import React, { useState, useMemo, useEffect } from 'react';
import {
  IoCloseOutline,
  IoExpandOutline,
  IoCloudDownloadOutline,
} from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Download = () => {
  const [download, setDownload] = useState({ italianDocs: [], englishDocs: [] });
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [filter, setFilter] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [isBrowser, setIsBrowser] = useState(false);

  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const fetchDownload = async (page = 1, allDownloads = []) => {
    if (!isBrowser) return;
    
    try {
      const response = await axios.get(`https://grec-backend.onrender.com/api/manuals?depth=1&page=${page}`);
      const downloadsList = [...allDownloads, ...response.data.docs];
      const totalPages = response.data.totalPages;

      const downloads = downloadsList.map(download => ({
        ...download,
        docUrl: download.file ? `https://grec-backend.onrender.com/api/media/${download.file}` : null
      }));

      const sortedDownloads = downloads.sort((a, b) => a.order - b.order);

      const italianDocs = sortedDownloads.filter(doc => doc.language === 'it');
      const englishDocs = sortedDownloads.filter(doc => doc.language === 'en');

      setDownload({ italianDocs, englishDocs });
      setTotalPages(totalPages);

      if (page < totalPages) {
        fetchDownload(page + 1, downloadsList);
      }
    } catch (error) {
      console.error('Error fetching downloads:', error);
    }
  };

  useEffect(() => {
    if (isBrowser) {
      fetchDownload();
    }
  }, [isBrowser]);

  const handleDocumentClick = (e, link, title) => {
    e.preventDefault();
    if (!isBrowser) return;

    if (window.innerWidth > 1024) {
      setSelectedPdf(link);
      setActiveItem(title);
    } else {
      window.open(link, '_blank');
    }
  };

  const closePdfViewer = () => {
    setSelectedPdf(null);
    setActiveItem(null);
  };

  const openFullscreen = () => {
    if (isBrowser && selectedPdf) {
      window.open(selectedPdf, '_blank');
    }
  };

  const downloadPdf = () => {
    if (!isBrowser || !selectedPdf) return;

    const link = document.createElement('a');
    link.href = selectedPdf;
    link.download = selectedPdf.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredItalianDocs = useMemo(() => 
    download.italianDocs.filter(doc => doc.title.toLowerCase().includes(filter.toLowerCase())),
    [download.italianDocs, filter]
  );

  const filteredEnglishDocs = useMemo(() => 
    download.englishDocs.filter(doc => doc.title.toLowerCase().includes(filter.toLowerCase())),
    [download.englishDocs, filter]
  );

  const DocumentList = ({ docs, title, img }) => (
    <div className="grec-document-list">
      <div className='grec-document-list-title'>
        <img style={{ height: '30px' }} src={img} alt={title} />
        <h2>{title}</h2>
      </div>
      <div className="grec-documents-container">
        {docs.map((document, index) => (
          <a
            key={index}
            className={`grec-document-item ${activeItem === document.title ? 'active' : ''} ${selectedPdf ? 'pdf-open' : ''}`}
            href={document.docUrl}
            onClick={(e) => handleDocumentClick(e, document.docUrl, document.title)}
          >
            {document.title}
          </a>
        ))}
      </div>
    </div>
  );

  return (
    <section className='page--container'>
      <div className='Grec-title-container'>
        <h1>{t('download.title', 'Download')}</h1>
        <p>{t('download.description', 'Access our manuals and technical brochures for detailed information about our products and mixing solutions.')}</p>
        <input 
          type="text" 
          placeholder={t('download.filterPlaceholder', 'Filter Documents...')}
          value={filter} 
          onChange={(e) => setFilter(e.target.value)}
          className="grec-pdf-filter"
        />
      </div>
      <section className={`grec-download-section ${selectedPdf ? 'grec-with-pdf' : ''}`}>
        <div className="grec-document-lists-container">
          <DocumentList docs={filteredItalianDocs} title={t('download.italianDocuments', 'Italian Documents')} img="/icons/italy-circular.svg" />
          <DocumentList docs={filteredEnglishDocs} title={t('download.englishDocuments', 'English Documents')} img="/icons/great-britain-circular.svg" />
        </div>
        {isBrowser && selectedPdf && (
          <div className="grec-pdf-viewer">
            <div className="grec-pdf-controls">
              <button onClick={openFullscreen}><IoExpandOutline size="25" /> {t('download.fullScreen', 'Full Screen')}</button>
              <button onClick={downloadPdf}><IoCloudDownloadOutline size="25" />{t('download.download', 'Download')}</button>
              <button onClick={closePdfViewer}><IoCloseOutline size="25" />{t('download.close', 'Close')}</button>
            </div>
            <iframe src={selectedPdf} title="PDF Viewer" />
          </div>
        )}
      </section>
    </section>
  );
};

export default Download;