import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoLogoLinkedin, IoLogoFacebook } from 'react-icons/io5';


//// // // // // // // // //// // // // // //   Lazy-loaded 
const ProductCatalog = lazy(() => import('./components/ProductCatalog'));
const ApplicazioniHome = lazy(() => import('./components/ApplicazioniHome'));
const DownloadsSection = lazy(() => import('./components/DownloadsSection'));
const ClickableCategoryImages = lazy(() => import('./components/ClickableCategoryImages'));
const Fiera = lazy(() => import('./components/Fiera'));
const Map3D = lazy(() => import('./components/Map3D'));

//// // // // // // // // // // // // //  LazyLoadComponent
const LazyLoadComponent = ({ children, placeholder }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current);
        }
      },
      {
        rootMargin: '100px', /// // // // // // // / Start loading when within 100px of viewport
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref}>
      {isVisible ? (
        <Suspense fallback={placeholder}>
          {children}
        </Suspense>
      ) : (
        placeholder
      )}
    </div>
  );
};

function Home() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const newVideoRef = useRef(null);

  const productList = ['AVF', 'AVS', 'AVL', 'AV', 'AVC', 'IN-LINEA'];
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);
  return (
    <div className="App">
      <main>
        <section  className="video-section">
          <div className="video-container">
            <div className="video-button">
              <h1>{t('home.title')}</h1>
              <Link to="/prodotti"><button>{t('home.viewProducts')}</button></Link>
              <Link to="/chi-siamo"><button className='button-variant-2'>{t('home.aboutUs')}</button></Link>
            </div>
            <div className="video-social">
              <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/grec-srl/" aria-label="LinkedIn">
                <IoLogoLinkedin size={35} />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/grecsrl" aria-label="Facebook">
                <IoLogoFacebook size={35} />
              </a>
            </div>
            {isBrowser && (
          <video 
            ref={newVideoRef} 
            className="video" 
            autoPlay 
            muted 
            loop 
            playsInline
            aria-label="Company promotional video"
          >
            <source src="/animation3.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
          </div>
        </section>

        <section className='Grec-subtitle-container'>
          <p>{t('home.subtitle')}</p>
        </section>

        <LazyLoadComponent placeholder={<div>Loading Product Catalog...</div>}>
          <ProductCatalog />
        </LazyLoadComponent>

        <LazyLoadComponent placeholder={<div>Loading Applications...</div>}>
          <ApplicazioniHome />
        </LazyLoadComponent>

        <LazyLoadComponent placeholder={<div>Loading Downloads Section...</div>}>
          <DownloadsSection />
        </LazyLoadComponent>

        <LazyLoadComponent placeholder={<div>Loading Category Images...</div>}>
          <ClickableCategoryImages />
        </LazyLoadComponent>

        <LazyLoadComponent placeholder={<div>Loading Fiera...</div>}>
          <Fiera />
        </LazyLoadComponent>

        <LazyLoadComponent placeholder={<div>Loading 3D Map...</div>}>
          <Map3D />
        </LazyLoadComponent>

        <section className='fireWall'>
          <img src="https://utfs.io/f/zwernDKKkPo0Aes9cI825xT6QmNW9weyajzOrKRspPXYMcVn" alt="Firewall logo" />
          <p>
            {i18n.language === 'en' ? (
              <>
                <strong>Firewall | WEB & DESIGN</strong> and <strong>Firewall | DPO</strong> are committed to following ethical practices in UI design and data usage. This includes accessible interfaces, respect for privacy, minimal data collection, and compliance with <u>data protection regulations such as GDPR</u>.
              </>
            ) : (
              <>
                <strong>Firewall | WEB & DESIGN</strong> e <strong>Firewall | DPO</strong> si impegnano a seguire pratiche etiche nella progettazione UI e nell'utilizzo dei dati. Ciò include interfacce accessibili, rispetto della privacy, raccolta minima dei dati, e conformità alle <u>normative sulla protezione dei dati come il GDPR</u>.
              </>
            )}
          </p>
        </section>
      </main>
    </div>
  );
}

export default Home;