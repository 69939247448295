import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import data from "../noSQL/data"
import { useTranslation } from 'react-i18next';

const categoryImages = {
  'AGITATORI VERTICALI': '/vito/AGITATORI_VERTICALI.png',
  'AGITATORI LATERALI': '/vito/AGITATORI_LATERALI.png',
  'AGITATORI PORTATILI': '/vito/AGITATORI_PORTATILI.png',
  'IN LINEA': '/vito/AGITATORI_IN-LINEA.png',
  'GIRANTI': '/vito/ELICHE.png'
};

const categoryDescriptions = {
  it: {
    'AGITATORI VERTICALI': '<strong>Montati sul tetto del serbatoio.</strong><br/>Sono la soluzione più flessibile per ogni applicazione.',
    'AGITATORI LATERALI': '<strong>Montati nella parte bassa della parete laterale.</strong><br/>Solo per stoccaggi o miscelazioni semplici.',
    'AGITATORI PORTATILI': '<strong>Fissati su strutture mobili o attacchi rapidi</strong><br/>Tipo morsetto per le cantine attacchi Garolla DIN o speciale',
    'IN LINEA': '<strong>Miscelatori</strong><br/>Per miscelazioni in linea, regolate dalla potenza o dalla portata del fluido.',
    'GIRANTI': '<strong>Giranti</strong><br/>La girante è un dispositivo che trasforma la potenza di alimentazione in diverse quote di:<br/><ul><li><strong>Taglio S</strong> (movimento microscopico che disgrega i legami)</li><li><strong>Prevalenza H</strong> (vince la resistenza al moto in liquidi viscosi)</li><li><strong>Portata Q</strong> (movimento macroscopico in una direzione principale)</li></ul>Generando uno sbandieramento F sull\'albero l\'obiettivo è per ogni singola applicazione, fornire le giuste quantità di S, H, Q, limitando F entro limiti di accettabilità.<br/>I parametri della girante che influiscono sulle quote di S, H, Q, F sono:<ul><li>Numero pale N</li><li>Larghezza pala W (si chiamano ad alta efficienza quelle con pala sottile ed alta prevalenza quelle con pala larga)</li><li>Angolo pala rispetto alla verticale α (si chiamano radiali quelle con angolo a 90° e assiali quelle con angolo < 45°)</li><li>Velocità di rotazione RPM</li></ul>'
  },
  en: {
    'VERTICAL AGITATORS': '<strong>Mounted on the tank roof.</strong><br/>They are the most flexible solution for any application.',
    'SIDE AGITATORS': '<strong>Mounted in the lower part of the side wall.</strong><br/>Only for storage or simple mixing.',
    'PORTABLE AGITATORS': '<strong>Fixed on mobile structures or quick couplings</strong><br/>Such as clamps for cellars, Garolla DIN or special attachments',
    'IN-LINE': '<strong>Mixers</strong><br/>For in-line mixing, regulated by the power or flow rate of the fluid.',
    'IMPELLERS': '<strong>Impellers</strong><br/>The impeller is a device that transforms the input power into different quotas of:<br/><ul><li><strong>Shear S</strong> (microscopic movement that breaks down bonds)</li><li><strong>Head H</strong> (overcomes resistance to motion in viscous liquids)</li><li><strong>Flow rate Q</strong> (macroscopic movement in a main direction)</li></ul>Generating a sway F on the shaft, the goal is for each individual application to provide the right amounts of S, H, Q, limiting F within acceptable limits.<br/>The impeller parameters that influence the S, H, Q, F quotas are:<ul><li>Number of blades N</li><li>Blade width W (those with thin blades are called high efficiency and those with wide blades are called high head)</li><li>Blade angle relative to vertical α (those with a 90° angle are called radial and those with an angle < 45° are called axial)</li><li>Rotation speed RPM</li></ul>'
  }
};
const CategoryProducts = () => {
  const { category } = useParams();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const categoryMap = {
    it: {
      'agitatori-verticali': 'AGITATORI VERTICALI',
      'agitatori-laterali': 'AGITATORI LATERALI',
      'agitatori-portatili': 'AGITATORI PORTATILI',
      'in-linea': 'IN LINEA',
      'giranti': 'GIRANTI'
    },
    en: {
      'agitatori-verticali': 'VERTICAL AGITATORS',
      'agitatori-laterali': 'SIDE AGITATORS',
      'agitatori-portatili': 'PORTABLE AGITATORS',
      'in-linea': 'IN-LINE',
      'giranti': 'IMPELLERS'
    }
  };

  const reverseEnglishMap = Object.fromEntries(
    Object.entries(categoryMap.en).map(([key, value]) => [value, categoryMap.it[key]])
  );

  const originalCategory = categoryMap[currentLanguage][category] || category.toUpperCase().replace(/-/g, ' ');
  const italianCategory = currentLanguage === 'it' ? originalCategory : reverseEnglishMap[originalCategory];

  const filteredData = data.filter(item => {
    if (currentLanguage === "it") {
      return item.category === originalCategory;
    } else if (currentLanguage === "en") {
      return item.categoryEng === originalCategory;
    }
  });


 const groupedData = filteredData.reduce((acc, item) => {
  const key = currentLanguage === 'it' ? item.description2 : item.description2Eng;
  if (!acc[key]) {
    acc[key] = [];
  }
  acc[key].push(item);
  return acc;
}, {});

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
    },
  }),
};


return (
  <section className='page--container'>
    <div className='Grec-title-container'>
      <h1>{originalCategory}</h1>
      <p className='p-danger' dangerouslySetInnerHTML={{ __html: categoryDescriptions[currentLanguage][originalCategory] }}></p>
    </div>
    <img
      src={categoryImages[italianCategory]}
      alt={`${originalCategory} category`}
      className="category-image-img"
    />
    {Object.entries(groupedData).map(([description, items], groupIndex) => (
      <div key={groupIndex}>
        <h2 className="description-title">{description}</h2>
        <div className="Grec--card-container">
          {items.map((item, index) => (
            
            <motion.div
              key={item.id}
              className="Grec--machine-card"
              initial="hidden"
              animate="visible"
              custom={index}
              variants={cardVariants}
            >
              <Link to={`/prodotti/${item.route}`}>
                <div>
                  <div className='card-img-container'>
                    <img src={item.img} alt={item.title} />
                  </div>
                  <h2>{item.title}</h2>
                  <p>{currentLanguage === 'it' ? item.description2 : item.description2Eng}</p>
                  <span>{currentLanguage === 'it' ? item.description1 : item.description1Eng}</span>
                </div>
              </Link>
            </motion.div>
          ))}


)}
        </div>
      </div>
    ))}
    <br/><br/><br/><br/>
  </section>
);
};

export default CategoryProducts;