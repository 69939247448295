import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const SEOHelmet = ({ 
  title,
  description,
  keywords,
  author = 'GREC srl',
  canonicalUrl,
  ogImage,
  ogUrl,
  twitterImage,
}) => {
  const { t, i18n } = useTranslation();

  const lang = i18n.language;
  const isItalian = lang === 'it';

  const seoTitle = title || t('home.title');
  const seoDescription = description || t('home.subtitle');
  const seoKeywords = keywords || t('home.keywords', { defaultValue: 'agitatori, dispersori, sistemi di tenuta, giranti, miscelatori' });

  return (
    <Helmet>
      <html lang={lang} />
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      <meta name="keywords" content={seoKeywords} />
      <meta name="author" content={author} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      
      <meta property="og:site_name" content={isItalian ? "GREC srl - Agitatori Industriali" : "GREC srl - Industrial Agitators"} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:type" content="website" />
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seoDescription} />
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}
      
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default SEOHelmet;