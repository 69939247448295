import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

function Lavora() {

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
    return (
        <>

<section className='page--container' >
                <div className='Grec-title-container'>
                <h1>{currentLanguage === 'en' ? 'Work with Us' : 'Lavora con noi'}</h1>
                <p>
                {currentLanguage === 'en' ? 'The methods of managing the site with regard to the processing of personal data of users who consult it.' : 'Le modalità di gestione del sito in riferimento al trattamento dei dati personali degli utenti che lo consultano.'}
                        
                    </p>
                </div>
           </section>

<div className='work-con-noi' >
< LavoriList />
<ContactForm/>
        
    </div>
         
            <br /> <br />
         
    
        </>
    );
}

export default Lavora;

const LavoriList = () => {
  const [lavori, setLavori] = useState([]);
  const [openLavoroId, setOpenLavoroId] = useState(null);
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
    if (typeof window !== 'undefined') {
      const fetchLavori = async () => {
        try {
          const response = await axios.get('https://grec-backend.onrender.com/api/lavoro');
          setLavori(response.data.docs);
        } catch (error) {
          console.error('Errore durante il recupero dei lavori:', error);
        }
      };

      fetchLavori();
    }
  }, []);

    const renderRichText = (richText) => {
        return richText.map((node, index) => {
            switch (node.type) {
                case 'h1':
                    return <h1 key={index}>{node.children.map(child => child.text)}</h1>;
                case 'h2':
                    return <h2 key={index}>{node.children.map(child => child.text)}</h2>;
                case 'h3':
                    return <h3 key={index}>{node.children.map(child => child.text)}</h3>;
                case 'p':
                    return <p key={index}>{node.children.map(child => child.text)}</p>;
                case 'ul':
                    return <ul key={index}>{node.children.map((child, i) => <li key={i}>{child.text}</li>)}</ul>;
                case 'ol':
                    return <ol key={index}>{node.children.map((child, i) => <li key={i}>{child.text}</li>)}</ol>;
                case 'link':
                    return <a key={index} href={node.url} target={node.newTab ? '_blank' : '_self'}>{node.children.map(child => child.text)}</a>;
                case 'img':
                    return <img key={index} src={node.url} alt={node.alt} />;
                default:
                    return <span key={index}>{node.children.map(child => child.text)}</span>;
            }
        });
    };

    const handleToggle = (id) => {
        setOpenLavoroId(openLavoroId === id ? null : id);
    };

    return (
        <div className="lavori-list">
            {lavori.map((lavoro) => (
                <div key={lavoro.id} className="lavoro-item">
                    <div className="lavoro-header" onClick={() => handleToggle(lavoro.id)}>
                        <h2>{lavoro.titoloDelLavoro}</h2>
                        
                        <span>{lavoro.tipoDiContratto}</span>
                        <span>{new Date(lavoro.dataPubblicazione).toLocaleDateString()}</span>
                        {openLavoroId === lavoro.id ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </div>
                    {openLavoroId === lavoro.id && (
                        <div className="lavoro-content">
                            <p><strong>Dipartimento:</strong> {lavoro.dipartimento}</p>
                            <p><strong>Descrizione del lavoro:</strong> {renderRichText(lavoro.descrizioneDelLavoro)}</p> {/* Render del rich text */}
                            <p><strong>Requisiti:</strong> {lavoro.requisiti}</p>
                            <p><strong>Titolo di studio:</strong> {lavoro.titoloDiStudio}</p>
                            <p><strong>Tipo di contratto:</strong> {lavoro.tipoDiContratto}</p>
                            <p><strong>Data di pubblicazione:</strong> {new Date(lavoro.dataPubblicazione).toLocaleDateString()}</p> {/* Render della data */}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};



const ContactForm = () => {

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

    const [formData, setFormData] = useState({
      name: '',
      email: '',
      subject: '',
      message: '',
      file: null,
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleFileChange = (e) => {
      setFormData({
        ...formData,
        file: e.target.files[0],
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('subject', formData.subject);
      formDataToSend.append('message', formData.message);
      if (formData.file) {
        formDataToSend.append('file', formData.file);
      }
  
      try {
        await axios.post('https://grec-backend.onrender.com/send-email', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Failed to send email');
      }
    };
  
    return (
      <form className='Grec--contact-form' onSubmit={handleSubmit}>
        <p className='p-form-header'  >
        <strong>
          {currentLanguage === 'en'
            ? "Send your spontaneous application to work with us."
            : "Invia la tua candidatura spontanea per lavorare con noi."}
        </strong>
        <br />
        {currentLanguage === 'en'
          ? "We are looking for young talents, experienced professionals, and industry specialists."
          : "Cerchiamo giovani talenti, esperti professionisti e specialisti di settore."}
        </p>
        <div className='Grec--contact-head'>
       
          <input  class="input-full" placeholder={currentLanguage === 'en' ? 'Name' : 'Nome'} type="text" name="name" value={formData.name} onChange={handleChange} required />
          <input  class="input-full" placeholder='Email' type="email" name="email" value={formData.email} onChange={handleChange} required />
       
        </div>
        <div>
         
         
        </div>
        <div>
       
          <input   class="input-full" placeholder={currentLanguage === 'en' ? 'Subject' : 'Oggetto'} type="text" name="subject" value={formData.subject} onChange={handleChange} required />
        </div>
        <div>
       
          <textarea class="input-full" placeholder={currentLanguage === 'en' ? 'Message' : 'Messaggio'} name="message" value={formData.message} onChange={handleChange} required></textarea>
        </div>
        <div>
        
          <input   placeholder={currentLanguage === 'en' ? 'Attachment' : 'Allegato'} type="file" name="file" onChange={handleFileChange} />
        </div>
        <br/>
        <button type="submit">  {currentLanguage === 'en' ? 'Send Application' : 'Invia Candidatura'}</button>
      </form>
    );
  };
  